"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractsForChainOrThrow = void 0;
const contracts_1 = require("@nouns/contracts");
const addresses_1 = require("./addresses");
/**
 * Get contract instances that target the Ethereum mainnet
 * or a supported testnet. Throws if there are no known contracts
 * deployed on the corresponding chain.
 * @param chainId The desired chain id
 * @param signerOrProvider The ethers v5 signer or provider
 */
const getContractsForChainOrThrow = (chainId, signerOrProvider) => {
    const addresses = (0, addresses_1.getContractAddressesForChainOrThrow)(chainId);
    return {
        alpsTokenContract: contracts_1.AlpsTokenFactory.connect(addresses.alpsToken, signerOrProvider),
        alpsAuctionHouseContract: contracts_1.AlpsAuctionHouseFactory.connect(addresses.alpsAuctionHouseProxy, signerOrProvider),
        alpsDescriptorContract: contracts_1.AlpsDescriptorFactory.connect(addresses.alpsDescriptor, signerOrProvider),
        alpsSeederContract: contracts_1.AlpsSeederFactory.connect(addresses.alpsSeeder, signerOrProvider),
        alpsDaoContract: contracts_1.AlpsDaoLogicV1Factory.connect(addresses.alpsDAOProxy, signerOrProvider),
    };
};
exports.getContractsForChainOrThrow = getContractsForChainOrThrow;
